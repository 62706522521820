import React from 'react';
import {Link} from 'react-scroll';
//importing footer css
import './footer.css';
//importing footer information
import {FooterInfo} from '../../assets/Data';

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container container">
            <h1 data-aos="zoom-in" className="footer__title">{FooterInfo.Title}</h1>

            <ul className="footer__list">
                {
                    FooterInfo.Menu.map(({Id,Name,FLink})=>{
                        return(
                            <li data-aos="zoom-in" key={Id}>
                                <Link to={FLink} spy={true} hashSpy={true} smooth={true} offset={-10.5} duration={100}  className="footer__link">{Name}</Link>
                            </li>
                        )
                    })
                }
            </ul>

            <div className="footer__social">
                {
                    FooterInfo.SocialLink.map(({Id,Icon,Link})=>{
                        return(
                            <a data-aos="zoom-in" href={Link} className="home__social-icon footer__social-link" target="_blank" rel="noopener noreferrer" key={Id}>
                                <i className={Icon}></i>
                            </a>
                        )
                    })
                }               
            </div>
            <span className="footer__copy">{FooterInfo.CopyRight}</span>
        </div>
    </footer>
  )
}

export default Footer
