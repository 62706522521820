import React, {useEffect} from 'react';
import {aboutInfo} from '../../assets/Data';
// importing aos animation
import AOS from 'aos';
import 'aos/dist/aos.css';

function Info() {
  useEffect(()=> {
    AOS.init({
      duration:1200,
      easing:"ease",
      once:false,
    });
  });
  return ( 
    <div className='about__info grid'>
      {
        aboutInfo.map(({Id, Title, subTitle, Icon}) =>{
          return (
            <div className="about__box" key={Id} data-aos="zoom-in-up">
              <i className={`${Icon} about__icon`}></i>
              <h3 className="about__title">{Title}</h3>
              <span className="about__subtitle">{subTitle}</span>
           </div>
          )
        })
      }      
    </div>
  )
}

export default Info
