import React from 'react';
//importing home social information
import {SocialLink} from '../../assets/Data';

function Social() {
  return (
    <>
      <div className="home__social" data-aos="fade-up">
        {
          SocialLink.map(({Id, Title, Link, Icon}) =>{          
            return (
              <a href={Link} className="home__social-icon" target="_blank" rel="noopener noreferrer" key={Id} >
                {Icon}
              </a>
            )
          })
        }
        <span className="VLine"></span>
      </div>
    </>
  )
}

export default Social
