import React from 'react';
import {animateScroll as scroll} from 'react-scroll';
//importing scroll up css
import './scrollup.css';
//importing unicons
import { UilArrowUp } from '@iconscout/react-unicons';

const ScrollUp = () => {
    window.addEventListener("scroll", function(){
        const scrollUp = document.querySelector(".scrollup");
        if(scrollUp !== null && scrollUp !== "" && scrollUp !== undefined){
          this.scrollY >= 460 ? scrollUp.classList.add("show-scroll") : scrollUp.classList.remove("show-scroll");
        }        
    })
  return (
    <div onClick={()=>scroll.scrollToTop()} duration={10} delay={0} className="scrollup">
        <UilArrowUp className='scrollup__icon' ></UilArrowUp>
    </div>
  )
}

export default ScrollUp
