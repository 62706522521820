import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
// importing css
import "./App.css";
// importing all components
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Services from './components/services/Services';
import Qualification from './components/qualification/Qualification';
import Portfolio from './components/portfolio/Portfolio';
import Blog from './components/blog/Blog';
import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';
import Stages from './components/stages/Stages';
import Action from './components/action/Action';
import Counter from './components/counter/Counter';
// importing aos animation
import AOS from 'aos';
import 'aos/dist/aos.css';
// importing react spinners
import HashLoader from "react-spinners/HashLoader";
// importing theme mode
import { colorThemeMode } from './assets/Data';

const App = () => {
    //Get List Theme Modal Class Name
    const themes = colorThemeMode.reduce((acc, val, i) => {
      acc.push(val.ClassName);
      return acc;
    }, [])
    const [loading, setLoading] = useState(true);
    const params = useParams();
    //Set Theme Mode 
    useEffect(()=>{
      let theme_ = params['theme'] ? ( themes.includes(params['theme']) ? params['theme'] : themes[0] ) : themes[0]; 
      document.body.className = theme_;
    }, [params]);
  
    useEffect(()=>{
      setLoading(false);
      //Set AOS Animation
      AOS.init({
        duration:1200,
        easing:"ease",
        once:false,
        offset: 80,
      });

      return ()=>{
        setLoading(false);
      }
    }, []);
    
    return (
      <>
      {
        loading?
        <>
          <HashLoader color={'#1c1c1c'} loading={HashLoader} size={100}  style={{textAlign:"center"}}/>      
        </>
        :
        <>
          <Header />
          <main className="main" style={{ userSelect: "none" }}>
            <Home />
            <About />
            <Skills />      
            <Qualification />   
            <Services />     
            <Stages />   
            <Action/>     
            <Portfolio />
            <Counter />
            <Blog /> 
            <Testimonials/>  
            <Contact/>        
          </main>
          <Footer/>
          <ScrollUp/>
        </>
      }    
      </>
    )
  }

export default App
