import React from 'react';
//importing scroll down information
import {Home,allSvg} from '../../assets/Data';
//importing unicons
import { UilArrowDown } from '@iconscout/react-unicons';

function ScrollDown() {
  return (
    <div className='home__scroll'>
      <a href="#about" className="home__scroll-button button--flex">
        {allSvg[3].svg}
        <span className="home__scroll-name">{Home[0].homeScrollDown}</span>
        <UilArrowDown className="home__scroll-arrow"></UilArrowDown>
      </a>
    </div>
  )
}

export default ScrollDown
