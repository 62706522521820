import React, {useState} from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
// importing counter css
import './counter.css';
// importing counter information
import {Counters} from '../../assets/Data';

const Counter = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <>
      <section className="counters section" id="counters">
        <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
          <div className="counters__container container grid">
              {
                Counters.map(({Id, Title, Icon, CounterNumber}) => {
                  return(
                    <div className="counters__content" key={Id} data-aos="zoom-in">
                      <i className={Icon}></i>
                      <h4>{Title}</h4>
                      <span className="counter-number">{counterOn && <CountUp start={0} end={CounterNumber}></CountUp>}</span>                  
                    </div>
                  )
                })
              }            
            </div>
        </ScrollTrigger>        
      </section>
    </>
  )
}

export default Counter
