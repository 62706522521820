import React, { useState } from 'react';
//importing services css
import './services.css';
//importing services information
import {Title, Service, allSvg} from '../../assets/Data';
//importing unicons
import { UilTimes } from '@iconscout/react-unicons'

const Services = () => {
    const [toggleState, setToggleState] = useState(0);
    
    const toggleTab = (index) =>{
        //alert(index)
        setToggleState(index);
    }
  
  return (
    <section className="services section" id="services" >    
        <h2 className="section__title" data-aos="fade-down">{Title[3].sectionTitle}</h2>
        <span className="section__subtitle" data-aos="zoom-in">{Title[3].sectionSubTitle}</span>
        <span className="section__zigzag" data-aos="zoom-in">{allSvg[4].svg}</span>

        <div className="services__container container grid">
            {
                Service.map(({Id, Title, Icon, ServiceButtonTitle, ServiceButtonIcon, ModelTitle, ModelDescription, ModalServicesInfo}) =>{
                return (
                    
                    <div className="services__content" key={Id} data-aos="fade-right">
                       
                        <div data-aos="zoom-in-up">                        
                            {Icon}
                            <h3 className="services__title">
                                {Title}
                            </h3>
                        </div>
                        
                        <span data-aos="zoom-in" className="services__button" onClick={() => toggleTab(Id)}>{ServiceButtonTitle}{ServiceButtonIcon}</span>

                        <div className={toggleState === Id ? "services__modal active-modal" : "services__modal"}>
                            <div className="services__modal-content">                 
                                <UilTimes  onClick={() => toggleTab(0)} className="services__modal_close closeButton"></UilTimes>
                                <h3 className="services__modal-title">{ModelTitle}</h3>
                                <p className="services__modal-description">{ModelDescription}</p>
                            
                                <ul className="services__modal-services grid">
                                    {
                                        ModalServicesInfo.map(({InfoId, InfoIcon, Info}) =>{
                                            return (
                                                <li className="services__modal-service" key={InfoId}>
                                                    <i className={`${InfoIcon} services__modal-icon`} ></i>
                                                    <p className="services__modal-info">{Info}</p>
                                                </li>  
                                            )
                                        })
                                    }                                                                      
                                </ul>    
                            </div>            
                        </div>
                    </div>
                )
                })
            }   
       
        </div>
    </section>    
  )
}

export default Services
