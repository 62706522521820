import React from 'react';
//importing stages css
import './stages.css';
//importing stages information
import { Title, Stage, allSvg } from '../../assets/Data';

const Stages = () => {
   
  return (
    <>   
    <section className="howWorks section" id="howWorks" >    
        <h2 className="section__title" data-aos="fade-down">{Title[4].sectionTitle}</h2>
        <span className="section__subtitle" data-aos="zoom-in">{Title[4].sectionSubTitle}</span>
        <span className="section__zigzag" data-aos="zoom-in">{allSvg[4].svg}</span>
        
        <div className="stages__container container grid">
            {
                Stage.map(({Title, StageImage}, index) =>{
                    return (
                        <div data-aos="zoom-in" className="how-i-work-item" key={index}>
                            { Stage.length-1 !== index ? allSvg[5].svg : ""}
                            <div className="number">
                                <span>{`0${index+1}`}</span>
                            </div>
                            <div className="number-border"></div>
                            <div className="img">
                                <img src={StageImage.Img} className="img-fluid stage-img"
                                    alt={StageImage.alt} />
                            </div>
                            <div className="text">
                                <h5>{Title}</h5>
                            </div>
                        </div>
                    )
                })
            }               
        </div>        
    </section>
    </>
      
  )
}

export default Stages;

