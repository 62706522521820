import React from 'react';
//importing Frontend skill information
import {Skills, FrontendSkills} from '../../assets/Data';

function Frontend() { 
  return (
    <div className="skills__content" data-aos="fade-up-right">
      <h3 className="skills__title">{Skills.frontendSkillTitle}</h3>

      <div className="skills__box">
        <div className="skills__group">
          {
            FrontendSkills.slice(0,(Math.round(FrontendSkills.length /2))).map(({Id, Name, Level, Icon}) =>{
              return (
                <div className="skills__data" key={Id}>
                  <i className={Icon}></i>
                  <div>
                    <h3 className="skills__name">{Name}</h3>
                    <span className="skills__level">{Level}</span>
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="skills__group">        
          {
            FrontendSkills.slice(Math.round(FrontendSkills.length /2),FrontendSkills.length).map(({Id, Name, Level, Icon}) =>{
              return (
                <div className="skills__data" key={Id}>
                  <i className={Icon}></i>
                  <div>
                    <h3 className="skills__name">{Name}</h3>
                    <span className="skills__level">{Level}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Frontend
