import React from 'react';
// importing blog css
import './blog.css';
// importing blog information
import { Title, BlogInfo, allSvg } from '../../assets/Data';
// importing Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// importing Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
// importing required modules
import { Autoplay, Pagination } from "swiper";

const Blog = () => {
  
  return (
    <>
     <section className="blogs section" id="blogs" >     
        <h2 className="section__title" data-aos="fade-down">{Title[6].sectionTitle}</h2>
        <span className="section__subtitle" data-aos="zoom-in">{Title[6].sectionSubTitle}</span>
        <span className="section__zigzag" data-aos="zoom-in">{allSvg[4].svg}</span>

        <Swiper data-aos="flip-left" className="blogs__container container grid"
            loop={true}
            grabCursor={true}
            spaceBetween={24}
            autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            }}
            pagination={{
                dynamicBullets: true,
            }}
            breakpoints={{
                576: {
                slidesPerView: 2
                },
                770: {
                slidesPerView: 3,
                spaceBetween: 48,
                },
            }}
            modules={[Autoplay,Pagination]}
        >
            {
                BlogInfo.map(({Id, Img, Title, Date, Link, Icon, }) =>{
                    return(
                        <SwiperSlide className="blogs__content" key={Id} >
                            <div className="blog_single_item">
                                <img id="img-fluid" src={Img} alt="" />
                                <div className="blog_info">
                                    <h6 onClick={() => window.open(Link)} title="URL Shortener" target="_blank">{Title}</h6>
                                    <p>{Date}</p>
                                    <span onClick={() => window.open(Link)} target="_blank" className={Icon}></span>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })
            }            
        </Swiper>
    </section> 
    </>      
  )
}

export default Blog
