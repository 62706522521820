import React, {useEffect} from 'react';
//importing skills css
import './skills.css';
//importing component
import Frontend from './Frontend';
import Backend from './Backend';
//importing skill information
import {Title, allSvg} from '../../assets/Data';
// importing aos animation
import AOS from 'aos';
import 'aos/dist/aos.css';

function Skills() {
  useEffect(()=> {
    AOS.init({
      duration:1200,
      easing:"ease",
      once:false,
    });
  });
  return (
    <section className='skills section' id='skills'>    
      <h2 className="section__title" data-aos="fade-down">{Title[1].sectionTitle}</h2>
      <span className="section__subtitle" data-aos="zoom-in">{Title[1].sectionSubTitle}</span>
      <span className="section__zigzag" data-aos="zoom-in">{allSvg[4].svg}</span>

      <div className="skills__container container grid">
        <Frontend />
        
        <Backend />
      </div>
    </section>
  )
}

export default Skills
