import React from 'react';
//importing css information
import './home.css';
//importing component
import Social from './Social';
import Data from './Data';
import ScrollDown from './ScrollDown';

function Home() {
  return (
    <>
      <section className="home section" id="home">
        <div className="home__container container grid">
            <div className="home__content grid">
                <Social />
                <div data-aos="zoom-in-left" className="home__img"></div>
                <Data />
            </div>
            <ScrollDown />
        </div>            
      </section>
      
    </>
  )
}

export default Home
