import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
//importing landing page css
import './landing.css';
//importing landing page information
import { colorThemeMode } from "../../assets/Data";
//importing images
import BrandLogo from '../../assets/img/theme/Parthify.png';
import Separator from '../../assets/img/theme/Separator.png';
// importing react spinners
import HashLoader from "react-spinners/HashLoader";

export const Landing = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const changeTheme = (themeName) =>{
        navigate(`/${themeName}/`)
    }

    const  refreshPage = () => {
        window.location.reload(false);
    }

    useEffect(()=>{
        setLoading(false);
        document.body.className = "landing-theme"
    },[]);

    return (
        <>
            {
                loading?
        <>
          <HashLoader color={'#1c1c1c'} loading={HashLoader} size={100}  style={{textAlign:"center"}}/>      
        </>
        :
        <>
            <section className="landing__hero">   
                <img src={BrandLogo}  alt="Brand Logo" />            
                <h1>Parthify - Creative Portfolio Theme</h1>
                <h2>We provide most unique template for you, <br /> that build with React Js</h2>
                <a href="#DemoTheme" className="button button--flex"> View Demos </a> 
            </section>           
            <section className="landing__container" id="DemoTheme">   
                <div className="landing_main_demo">
                    <h1>Home Demo</h1>
                    <img src={Separator} alt="sd" /> 
                    <h2>Unique Light & Dark Demo</h2>
                    <h3>Unique React Template for the Creative, Personal Portfolio</h3> 
                   
                </div>
                <div className="card__wrapper">
                    {/* <div className="card" onClick={()=>{changeTheme('dark-theme')}}>Dark Theme</div> */}
                    {colorThemeMode.map(({ Title, ClassName, Img}, i) => {
                        return (
                            <div key={i}>
                                <div className="demo">
                                    <div className="portfolio__box portfolio__box1" style={{ backgroundImage: `url(${Img})`}}  onClick={()=>{changeTheme(ClassName)}}></div>
                                </div>                            
                                <h1 onClick={()=>{changeTheme(ClassName)}}>{Title}</h1>
                            </div>                
                        )
                    })}
                </div>
                <div className="landing__Other_demo">            
                    <h1>OTHER DEMO</h1>
                    <h3>Coming Soon..</h3>
                </div> 
            </section>             
            <footer className="landing__footer">             
                <h5>Parthify - Personal Portfolio React Template</h5>
                <h4>Copyright © 2022 Designed With <span onClick={refreshPage}>❤</span> BY <span onClick={refreshPage}>Parthify</span></h4>
            </footer>   
        </>
            }
        </>
       
    )
}