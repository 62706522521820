import React, { useState } from 'react';
import {Link,animateScroll as scroll} from 'react-scroll';
//importing header css
import "./header.css";
//importing header information
import {HeaderMenu} from '../../assets/Data';
//importing unicons
import { UilTimes, UilApps} from '@iconscout/react-unicons';

const Header = () => {
  /* ============ Change Background Header ============ */
    window.addEventListener("scroll", function(){
        const header = document.querySelector(".header");  
        if(header !== null && header !== "" && header !== undefined){
            this.scrollY >= 80 ? header.classList.add("scroll-header") : header.classList.remove("scroll-header");
        } 
    })

  /* ============ Toggle Men ============ */
  const   [Toggle, showMenu] = useState(false);     
  
  return (
    <>
      <header className="header" style={{ userSelect: "none" }}>
        <nav className="nav container">
            <div onClick={()=>scroll.scrollToTop()} duration={10} delay={10} className="nav__logo">Parthify</div>

            <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
                <ul className="nav__list grid">
                    {
                        HeaderMenu.map(({Id,Name,Icon,NavId}) => {
                            return(
                                <li className="nav__item" key={Id}>                                   
                                    <Link to={NavId} spy={true} hashSpy={true} smooth={true} offset={-10.5} duration={100} className="nav__link">
                                       {Icon} {Name} 
                                    </Link>
                                </li>
                            )                            
                        })
                    }                   
                </ul>
                <UilTimes className="nav__close" onClick={() => showMenu(!Toggle)}></UilTimes>
            </div>  
            <Link to="contact" spy={true} hashSpy={true} smooth={true} offset={-11} duration={100}  className="button HeaderBtn">Contact Us</Link>      
            <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                <UilApps />
            </div>
        </nav>
      </header>
      
    </>
  )
}

export default Header
