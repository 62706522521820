import React, {useEffect} from 'react';
// importing about css
import './about.css';
// importing component
import Info from './Info';
// importing about information
import {Title,About, allSvg} from '../../assets/Data';
// importing aos animation
import AOS from 'aos';
import 'aos/dist/aos.css';

function about() {
  useEffect(()=> {
    AOS.init({
      duration:1200,
      easing:"ease",
      once:false,
      offset: 80,
    });
  });

  return (
    <div>
      <section className="about section" id="about">
        <h2 data-aos="fade-up" className="section__title">{Title[0].sectionTitle}</h2>
        <span data-aos="fade-down" className="section__subtitle">{Title[0].sectionSubTitle}</span>
        <span data-aos="zoom-in" className="section__zigzag">{allSvg[4].svg}</span>

        <div className="about__container container grid">
            <img 
              src={About.AboutImg.Img} 
              alt={About.AboutImg.alt} 
              className="about__img" 
              data-aos="flip-left"
              />
            <div className="about__data">
                <Info />
                <p className="about__description" data-aos="zoom-in">
                    {About.AboutDescription}
                </p>
                <a 
                  download="" 
                  href={About.AboutDownloadCv} 
                  className="button button--flex" 
                  data-aos="fade-up"
                >
                 {About.AboutButtonTitle} {allSvg[1].svg}
                </a>
            </div>
        </div>    
      </section>
    </div>
  )
}

export default about
