import React from 'react';
//importing testimonials css
import './testimonials.css';
//importing testimonial information
import {Title, TestimonialData, allSvg } from '../../assets/Data';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
// import required modules
import { Autoplay, Pagination } from "swiper";

const Testimonials = () => {
  return (
   <section className="testimonial container section" id="testimonial">   
      <h2 className="section__title" data-aos="fade-down">{Title[7].sectionTitle}</h2>
      <span className="section__subtitle" data-aos="zoom-in">{Title[7].sectionSubTitle}</span>
      <span className="section__zigzag" data-aos="zoom-in">{allSvg[4].svg}</span>

      <Swiper data-aos="flip-down" className="testimonial__container"
          loop={true}
          grabCursor={true}
          spaceBetween={24}
          autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
          pagination={{
            dynamicBullets: true,
          }}
          breakpoints={{
            576: {
              slidesPerView: 2
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 48,
            },
          }}
          modules={[Autoplay,Pagination]}
      >
        {
          TestimonialData.map(({Id, image, title, description}) =>{
            return (
              <SwiperSlide className="testimonial__card" key={Id}>
                <img src={image} alt="" className="testimonial__img" />

                <h3 className="testimonial__name">{title}</h3>
                <p className="testimonial__description">{description}</p>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
   </section>
  )
}

export default Testimonials;
