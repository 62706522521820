import React from 'react';
// importing action css
import './action.css';
// importing action information
import {CallToAction} from '../../assets/Data';

const Action = () => {
  return (
    <>
      <section className="call-to-action section" id="call-to-action">
        <div className="call-to-action__container container grid">
          <div className="call-to-action">         
            <h6 data-aos="fade-right">{CallToAction.Title}</h6>
            <a data-aos="fade-right" className="button" href={CallToAction.Link}>{CallToAction.ButtonTitle}</a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Action
