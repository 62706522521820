import React, {useRef} from 'react';
// importing contact css
import './contact.css';
// importing formik validation
import { useFormik } from "formik";
import { contactSchema } from "../../schemas";
// importing emailjs
import emailjs from '@emailjs/browser';
// importing contact information
import {Title, allSvg, ContactInfo, ContactCardInfo, ContactFormSendKey} from '../../assets/Data';
 
const initialValues = {
    name: "",
    email: "",
    message: "",
};

const Contact = () => {
    const form = useRef();
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
        initialValues: initialValues,
        validationSchema: contactSchema,
        onSubmit: (values, action) => {          
            emailjs.sendForm(ContactFormSendKey.ServiceId, ContactFormSendKey.TemplateId, form.current, ContactFormSendKey.PublicKey)
                .then(() =>{
                    console.log("Message Sent 📤");
                },(error)=>{
                    console.log('OOPS! SOMETHING HAS FAILED... ', error);
                })
            action.resetForm();
        },
    });

  return (
    <section className="contact section" id="contact">    
        <h2 className="section__title" data-aos="fade-down">{Title[8].sectionTitle}</h2>
        <span className="section__subtitle" data-aos="zoom-in">{Title[8].sectionSubTitle}</span>
        <span className="section__zigzag" data-aos="zoom-in">{allSvg[4].svg}</span>
        
        <div className="contact__container container grid">
            <div className="contact__content">
                <h3 data-aos="zoom-in" className="contact__title">{ContactInfo.ContactTitle}</h3>

                <div className="contact__info"> 
                    {
                        ContactCardInfo.map(({Id, Title, SubTitle, Link, Icon , ButtonTitle, ButtonIcon}) =>{
                            return(
                                <div data-aos="flip-right" className="contact__card" key={Id}>
                                    <i className={`${Icon} contact__card-icon`}></i>

                                    <h3 className="contact__card-title">{Title}</h3>
                                    <span className="contact__card-data">{SubTitle}</span>

                                    <a href={Link} className="contact__button">
                                        {ButtonTitle} <i className={`${ButtonIcon} contact__button-icon`} ></i>
                                    </a>
                                </div>
                            )
                        })
                    }   
                </div>
            </div>

            <div className="contact__content">
                <h3 data-aos="zoom-in" className="contact__title">{ContactInfo.ContactFormTitle}</h3>
                <form data-aos="flip-left" ref={form} onSubmit={handleSubmit} className="contact__form">
                    <div className="contact__form-div">
                        <label htmlFor={ContactInfo.FormName.Id} className="contact__form-tag">{ContactInfo.FormName.Name}</label>
                        <input 
                            type="text" 
                            name={ContactInfo.FormName.Id}  
                            id={ContactInfo.FormName.Id} 
                            className="contact__form-input" 
                            placeholder={ContactInfo.FormName.Placeholder}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                            <p className="form-error">{errors.name}</p>
                         ) : null}
                    </div>
                    <div className="contact__form-div">
                        <label htmlFor={ContactInfo.FormEmail.Id} className="contact__form-tag">{ContactInfo.FormEmail.Name}</label>
                        <input 
                            type="email" 
                            name={ContactInfo.FormEmail.Id} 
                            id={ContactInfo.FormEmail.Id} 
                            className="contact__form-input" 
                            placeholder={ContactInfo.FormEmail.Placeholder}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                            <p className="form-error">{errors.email}</p>
                         ) : null}
                    </div>
                    <div className="contact__form-div contact__form-area">
                        <label htmlFor={ContactInfo.FormMessage.Id} className="contact__form-tag">{ContactInfo.FormMessage.Name}</label>
                        <textarea 
                            name={ContactInfo.FormMessage.Id} 
                            id={ContactInfo.FormMessage.Id} 
                            cols="30" 
                            rows="10" 
                            className="contact__form-input" 
                            placeholder={ContactInfo.FormMessage.Placeholder}
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></textarea>
                         {errors.message && touched.message ? (
                            <p className="form-error" style={{paddingTop:"147px"}}>{errors.message}</p>
                         ) : null}
                    </div>
                    <button  type="submit" className="button button--flex">
                        {ContactInfo.ButtonTitle} {allSvg[0].svg}
                    </button>
                </form>
            </div>
        </div>
    </section>
  )
}

export default Contact;
