import React, { useState, useEffect } from 'react';
// importing qualification css
import './qualification.css';
// importing qualification information
import {Title, Qualifications, QulEducation, QulExperience, allSvg} from '../../assets/Data';
// importing aos animation
import AOS from 'aos';
import 'aos/dist/aos.css';

const Qualification = () => {

  const [toggleState, setToggleState] = useState(1);
    
  const toggleTab = (index) =>{
      setToggleState(index);
  }

  useEffect(()=> {
    AOS.init({
      duration:1200,
      easing:"ease",
      once:false,
      offset: 80,
    });
  });

  return (
    <section className="qualification section">        
        <h2 className="section__title" data-aos="fade-down">{Title[2].sectionTitle}</h2>
        <span className="section__subtitle" data-aos="zoom-in">{Title[2].sectionSubTitle}</span>
        <span className="section__zigzag" data-aos="zoom-in">{allSvg[4].svg}</span>

        <div className="qualification__container container">
          <div className="qualification__tabs">
            <div data-aos="zoom-out" onClick={() => toggleTab(1)} className={toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}>
              {Qualifications[0].Icon} {Qualifications[0].Education}
            </div>
            <div data-aos="zoom-out" onClick={() => toggleTab(2)} className={toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}>
              {Qualifications[1].Icon} {Qualifications[1].Experience}
            </div>
          </div>
        

          <div className="qualification__section"  data-aos="fade-up">
            <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
              {
                QulEducation.map(({Id, Title, subTitle, Icon, Calender}) =>{
                    
                      return (
                          <div className="qualification__data" key={Id}> 
                          {Id % 2 !== 1 ? <>
                                            <div></div>
                                            <div>
                                                <span className="qualification__rounder"></span>
                                                <span className="qualification__line"></span>
                                            </div>
                                          </> : <></>}                     
                            <div>
                              <div className="qualification__calender">
                                {Icon}<span>{Calender}</span>
                              </div>
                              <h3 className="qualification__title">{Title}</h3>
                              <span className="qualification__subtitle">{subTitle}</span>                              
                            </div>
                            {Id % 2 === 1 ? <div>
                                              <span className="qualification__rounder"></span>
                                              <span className="qualification__line"></span>
                                            </div> : <></>}
                          </div>                        
                            
                      )          
                  })
              } 
            </div>

            <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
            {
              QulExperience.map(({Id, Title, subTitle, Icon, Calender}) =>{
                    
                      return (
                          <div className="qualification__data" key={Id}> 
                          {Id % 2 !== 1 ? <>
                                            <div></div>
                                            <div>
                                                <span className="qualification__rounder"></span>
                                                <span className="qualification__line"></span>
                                            </div>
                                          </> : <></>}                     
                            <div>
                              <div className="qualification__calender">
                                {Icon}<span>{Calender}</span>
                              </div>
                              <h3 className="qualification__title">{Title}</h3>
                              <span className="qualification__subtitle">{subTitle}</span>                             
                            </div>
                            {Id % 2 === 1 ? <div>
                                              <span className="qualification__rounder"></span>
                                              <span className="qualification__line"></span>
                                            </div> : <></>}
                          </div>                        
                            
                      )          
                  })
              } 
            </div>
          </div>
        </div>
    </section>
  )
}

export default Qualification
