import React from 'react';
//importing backend skill information
import {Skills, BackendSkills} from '../../assets/Data'; 

function Backend() {
  return (
    <div className="skills__content" data-aos="fade-up-right">
      <h3 className="skills__title">{Skills.backendSkillTitle}</h3>

      <div className="skills__box">
        <div className="skills__group">
          {
            BackendSkills.slice(0,(Math.round(BackendSkills.length /2))).map(({Id, Name, Level, Icon}) =>{
              return (
                <div className="skills__data" key={Id}>
                  <i className={Icon}></i>
                  <div>
                    <h3 className="skills__name">{Name}</h3>
                    <span className="skills__level">{Level}</span>
                  </div>
                </div>
              )
            })
          }        
        </div>

        <div className="skills__group">          
          {
            BackendSkills.slice(Math.round(BackendSkills.length /2),BackendSkills.length).map(({Id, Name, Level, Icon}) =>{
              return (
                <div className="skills__data" key={Id}>
                  <i className={Icon}></i>
                  <div>
                    <h3 className="skills__name">{Name}</h3>
                    <span className="skills__level">{Level}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Backend
