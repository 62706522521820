import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import App from './App';
import { Landing } from './components/landing/landing';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.StrictMode>
      <Routes>
        <Route path='/' exact element={<Landing />}></Route>
        <Route path='/:theme' element={<App/>}></Route>
        {/* <Route path='/' element={<App/>}></Route> */}
      </Routes>
    </React.StrictMode>
  </Router>
);

