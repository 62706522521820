import React, { useState } from 'react';
//importing portfolio css
import './portfolio.css';
//importing portfolio information
import {Title, PortfolioInfo, allSvg } from '../../assets/Data';
//importing unicons
import { UilTimes } from '@iconscout/react-unicons'


const Portfolio = () => {
    const [toggleState, setToggleState] = useState(0);
    
    const toggleTab = (index) =>{
        //alert(index)
        setToggleState(index);
    }

  return (
    <>
     <section className="portfolios section" id="portfolios" >        
        <h2 className="section__title" data-aos="fade-down">{Title[5].sectionTitle}</h2>
        <span className="section__subtitle" data-aos="zoom-in">{Title[5].sectionSubTitle}</span>
        <span className="section__zigzag" data-aos="zoom-in">{allSvg[4].svg}</span>

        <div className="portfolios__container container grid">
            {
                [...PortfolioInfo].reverse().slice(0, 6).map(({Id,Img,Title,Date,Link,Icon,Modal})=>{
                    return(
                        <div data-aos="fade-right" className="portfolios__content" key={Id}>
                            <div className="portfolio_single_item">
                                <img id='img-fluid' src={Img.Img} alt={Img.alt} />
                                <div className="portfolio_info">
                                    <h6 title={Title} onClick={() => window.open(Link)} target="_blank">{Title}</h6>
                                    <p>{Date}</p>
                                    <span onClick={() => toggleTab(Id)} className={Icon}></span>
                                </div>
                            </div>
                            <div className={toggleState === Id ? "portfolios__modal active-modal" : "portfolios__modal"}>
                                <div className="portfolios__modal-content">
                                    <UilTimes onClick={() => toggleTab(0)} className="portfolios__modal_close"></UilTimes> 
                                    <h3 className="portfolios__modal-title">{Modal.ModalTitle}</h3>        
                                    <div className='portfolios__modal-data'>
                                        <div>{Modal.ProjectType}</div>                                                       
                                        <div style={{cursor:"pointer"}} onClick={() => window.open(Modal.ViewProject.Link)} target="_blank">
                                            <i className={`${Modal.ViewProject.Icon} View__Project`}  style={{marginRight:"5px"}}></i>
                                            {Modal.ViewProject.Name} 
                                        </div>
                                        <div>
                                            <i className={Modal.Like.Icon} style={{paddingRight:"5px"}}></i>
                                            {Modal.Like.Name}    
                                        </div>
                                    </div>
                                    <img src={Modal.ModalImg.Img} alt={Modal.ModalImg.alt}  />
                                </div>            
                            </div>
                        </div>
                    )
                })
            }  
        </div>

    </section>    
    </>
      
  )
}

export default Portfolio;