import React from 'react';
//importing home data information
import {Home,allSvg} from '../../assets/Data';

function Data() {
  return (
    <div className='home__data'>
        <h5 className="home__MainTitle"  data-aos="fade-right" data-aos-delay="50" >Hi There, I'm</h5>
        <h1 className="home__title"  data-aos="fade-right" data-aos-delay="50">
          {Home[0].homeTitle} <span className='wave'>{allSvg[2].svg}</span>
        </h1>  
        <h3 className="home__subtitle" data-aos="fade-right" data-aos-delay="50">{Home[0].homeSubTitle}</h3>    
        <p className="home__description" data-aos="fade-right" data-aos-delay="50">{Home[0].homeDescription}</p>
        <a href="#contact" 
          className="button button--flex" 
          style={{content: Home[0].homeButtonTitle}}
          data-aos="fade-up" data-aos-delay="50"
        >         
          {Home[0].homeButtonTitle}
          {allSvg[0].svg}           
        </a>     
    </div>
  )
}

export default Data
