import React from 'react';
import AboutImg from "./img/user/about.png";

//importing landing page theme images
import Light_Theme from "./img/theme/Theme_Mode/light-theme.png";
import Dark_Theme from "./img/theme/Theme_Mode/dark-theme.png";
import Dark_Orange_Theme from "./img/theme/Theme_Mode/dark_orange-theme.png";
import Dark_Yellow_Theme from "./img/theme/Theme_Mode/dark_yellow-theme.png";
import Dark_Pink_Theme from "./img/theme//Theme_Mode/dark_pink-theme.png";
import Light_Orange_Theme from "./img/theme/Theme_Mode/light_orange-theme.png";
import Light_Yellow_Theme from "./img/theme/Theme_Mode/light_yellow-theme.png";
import Light_Pink_Theme from "./img/theme//Theme_Mode/light_pink-theme.png";
import Light_Blue_Theme from "./img/theme//Theme_Mode/light_blue-theme.png";

//importing stages images
import Thinking from "./img/stage/01_Thinking.png";
import Research from "./img/stage/02_Research.png";
import Design from "./img/stage/03_Design.png";
import Development from "./img/stage/04_Development.png";

//importing portfolio images
import Portfolio_01 from "./img/portfolio/01_Portfolio.png";
import Portfolio_02 from "./img/portfolio/02_Portfolio.png";
import Portfolio_03 from "./img/portfolio/03_Portfolio.png";
import Portfolio_04 from "./img/portfolio/04_Portfolio.png";
import Portfolio_05 from "./img/portfolio/05_Portfolio.png";
import Portfolio_06 from "./img/portfolio/06_Portfolio.png";

//importing blog images
import BUI from "./img/blog/01_UI.png";
import BDesign from "./img/blog/02_Design.png";
import BCoding from "./img/blog/03_Coding.png";

//importing Testimonial images
import Testimonial_01 from "./img/testimonial/01_Testimonial.jpg";
import Testimonial_02 from "./img/testimonial/02_Testimonial.jpg";
import Testimonial_03 from "./img/testimonial/03_Testimonial.jpg";
import Testimonial_04 from "./img/testimonial/04_Testimonial.jpg";

//importing resume pdf
import Cv from './Parth-Cv.pdf';

//importing unicons
import * as Unicons from '@iconscout/react-unicons';

/* ==========================================================================
   ALL SECTION INFORMATION
========================================================================== */

const Title = [
    {
        Id: 1,
        sectionTitle: "About Me",
        sectionSubTitle: <>My <span>Introduction</span></>,
        sectionMainTitle: "My Summary",
    },
    {
        Id: 2,
        sectionTitle: "Skills",
        sectionSubTitle: "My Technical Level",
        sectionMainTitle: <>Resume and <span>Details</span></>,
    },
    {
        Id: 3,
        sectionTitle: "Qualification",
        sectionSubTitle: "My personal journey",
        sectionMainTitle: <>Resume and <span>Details</span></>,
    },
    {
        Id: 4,
        sectionTitle: "WHAT WE DO",
        sectionSubTitle: <>Services and <span>Solutions</span></>,
        sectionMainTitle: "our services",
    },
    {
        Id: 5,
        sectionTitle: "HOW I WORK",
        sectionSubTitle: "I prepare your projects in 4 stages",
        sectionMainTitle: "Our projects",
    },
    {
        Id: 6,
        sectionTitle: "Workings Project",
        sectionSubTitle: "Latest Workings Project",
        sectionMainTitle: "Our Project",
    },
    {
        Id: 7,
        sectionTitle: "RECENT NEWS DESK",
        sectionSubTitle: <>Latest News & <span>Blogs</span></>,
        sectionMainTitle: "news & blog",
    },
    {
        Id: 8,
        sectionTitle: "Testimonial",
        sectionSubTitle: "What client say",
        sectionMainTitle: "My client review",
    },
    {
        Id: 9,
        sectionTitle: "Contact Me",
        sectionSubTitle: "ANY QUESTION?",
        sectionMainTitle: <>Drop Me A <span>Line</span></>,
    },
];

/* ==========================================================================
   HEADER SECTION INFORMATION
========================================================================== */

const HeaderMenu = [
    {
        Id: 1,
        Name: "Home",
        Icon: <Unicons.UilEstate className="nav__icon"/>,
        NavId: "home"
    },
    {
        Id: 2,
        Name: "About",
        Icon: <Unicons.UilUser className="nav__icon"/>,
        NavId: "about"
    },
    {
        Id: 3,
        Name: "Skills",
        Icon: <Unicons.UilFileAlt className="nav__icon"/>,
        NavId: "skills"
    },
    {
        Id: 4,
        Name: "Services",
        Icon: <Unicons.UilFileAlt className="nav__icon"/>,
        NavId: "services"
    },
    {
        Id: 5,
        Name: "Portfolio",
        Icon: <Unicons.UilBriefcaseAlt className="nav__icon"/>,
        NavId: "portfolios"
    },
    {
        Id: 6,
        Name: "Blog",
        Icon: <Unicons.UilBloggerAlt className="nav__icon"/>,
        NavId: "blogs"
    },
    {
        Id: 7,
        Name: "Contact",
        Icon: <Unicons.UilMessage className="nav__icon"/>,
        NavId: "contact"
    }
];

/* ==========================================================================
   HOME SECTION INFORMATION
========================================================================== */

const Home = [
    {
        homeTitle: "Parth Galani",
        homeSubTitle: "Full-Stack Developer",
        homeDescription: "I'm creative designer based in India, and I'm very passionate and dedicated to my work.",
        homeButtonTitle: "Say Hello",
        homeScrollDown: "Scroll Down"
    }
];

const SocialLink = [
    {
        Id: 1,
        Title: "Instagram",
        Link: "https://instagram.com/",
        Icon: <Unicons.UilInstagram/>
    },
    {
        Id: 2,
        Title: "GitHub",
        Link: "https://github.com/",
        Icon:  <Unicons.UilGithubAlt/>
    },
    {
        Id: 3,
        Title: "Skype",
        Link: "https://skype.com/",
        Icon: <Unicons.UilSkypeAlt />
    },
    {
        Id: 4,
        Title: "Facebook",
        Link: "https://facebook.com/",
        Icon: <Unicons.UilFacebook />
    }
];

/* ==========================================================================
   ABOUT SECTION INFORMATION
========================================================================== */

const About = {
    AboutImg: {
        Img: AboutImg,
        alt: "Parth Galani"
    },
    AboutDescription: "Frontend developer, I create web pages with UI / UX user interface, I have years of experience and many clients are happy with the projects carried out.",
    AboutButtonTitle: "Download CV",
    AboutDownloadCv: Cv,
};

const aboutInfo = [
    {
        Id: 1,
        Title: "Experience",
        subTitle: "3 Years Working",
        Icon: "bx bx-award"
    },
    {
        Id: 2,
        Title: "Completed",
        subTitle: "20 + Projects",
        Icon: "bx bx-briefcase-alt"
    },
    {
        Id: 3,
        Title: "Support",
        subTitle: "Online 24/7",
        Icon: "bx bx-support"
    }
];

/* ==========================================================================
   SKILL SECTION INFORMATION
========================================================================== */
const Skills = {
    frontendSkillTitle: "Frontend developer",
    backendSkillTitle: "Backend Developer"
};

const FrontendSkills = [
    {
        Id: 1,
        Name: "HTML",
        Level: "Advanced",
        Icon: "bx bx-badge-check"
    },
    {
        Id: 2,
        Name: "CSS",
        Level: "Advanced",
        Icon: "bx bx-badge-check"
    },
    {
        Id: 3,
        Name: "JavaScript",
        Level: "Intermediate",
        Icon: "bx bx-badge-check"
    },
    {
        Id: 4,
        Name: "Bootstrap",
        Level: "Intermediate",
        Icon: "bx bx-badge-check"
    },
    {
        Id: 5,
        Name: "React",
        Level: "Intermediate",
        Icon: "bx bx-badge-check"
    },
    {
        Id: 6,
        Name: "Git",
        Level: "Intermediate",
        Icon: "bx bx-badge-check"
    }
];

const BackendSkills = [
    {
        Id: 1,
        Name: "ASP .NET",
        Level: "Advanced",
        Icon: "bx bx-badge-check"
    },
    {
        Id: 2,
        Name: "Node Js",
        Level: "Basic",
        Icon: "bx bx-badge-check"
    },
    {
        Id: 3,
        Name: "MySQL",
        Level: "Intermediate",
        Icon: "bx bx-badge-check"
    },
    {
        Id: 4,
        Name: "Java",
        Level: "Intermediate",
        Icon: "bx bx-badge-check"
    },
    {
        Id: 5,
        Name: "Firebase",
        Level: "Basic",
        Icon: "bx bx-badge-check"
    },
    {
        Id: 6,
        Name: "Oracle SQL",
        Level: "Intermediate",
        Icon: "bx bx-badge-check"
    }
];

/* ==========================================================================
   QUALIFICATION SECTION INFORMATION
========================================================================== */

const Qualifications = [
    {
        Id: 1,
        Education: "Education",
        Icon: <Unicons.UilGraduationCap className="qualification__icon"/>
    },
    {
        Id: 2,
        Experience: "Experience",
        Icon: <Unicons.UilBriefcaseAlt className="qualification__icon"/>
    }
];

const QulEducation = [
    {
        Id: 1,
        Title: "Web Design",
        subTitle: "Oxford - Institute",
        Icon: <Unicons.UilCalendarAlt />,
        Calender: "2021 - Present"
    },
    {
        Id: 2,
        Title: "Art Director",
        subTitle: "Oxford - Institute",
        Icon: <Unicons.UilCalendarAlt />,
        Calender: "2020 - 2021"
    },
    {
        Id: 3,
        Title: "Web Development",
        subTitle: "Oxford - Institute",
        Icon: <Unicons.UilCalendarAlt />,
        Calender: "2019 - 2020"
    },
    {
        Id: 4,
        Title: "UX Expert",
        subTitle: "Oxford - Institute",
        Icon: <Unicons.UilCalendarAlt />,
        Calender: "2018 - 2019"
    }
];

const QulExperience = [
    {
        Id: 1,
        Title: "Product Designer",
        subTitle: "Microsoft - Canada",
        Icon: <Unicons.UilCalendarAlt />,
        Calender: "2021 - Present"
    },
    {
        Id: 2,
        Title: "UX Designer",
        subTitle: "Apple Inc - Canada",
        Icon: <Unicons.UilCalendarAlt />,
        Calender: "2020 - 2021"
    },
    {
        Id: 3,
        Title: "Web Designer",
        subTitle: "Figma - Canada",
        Icon: <Unicons.UilCalendarAlt />,
        Calender: "2019 - 2020"
    }
];

/* ==========================================================================
   SERVICES SECTION INFORMATION
========================================================================== */

const Service = [
    {
        Id: 1,
        Title: <>Product <br /> Designer</>,
        Icon: <Unicons.UilWebGrid className="services__icon"/>,
        ServiceButtonTitle: "View More",
        ServiceButtonIcon: <Unicons.UilArrowRight className="services__button-icon"/>,
        ModelTitle: "Product Designer",
        ModelDescription: "Service with more than 1 years of experience.Providing quality work to clients and companies.",
        ModalServicesInfo: [
            {
                InfoId: 1,
                InfoIcon: "bx bx-badge-check",
                Info: "I develop the user interface."
            },
            {
                InfoId: 2,
                InfoIcon: "bx bx-badge-check",
                Info: "Web page development."
            },
            {
                InfoId: 3,
                InfoIcon: "bx bx-badge-check",
                Info: "I create ux element interactions."
            },
            {
                InfoId: 4,
                InfoIcon: "bx bx-badge-check",
                Info: "I position your company brand."
            },
            {
                InfoId: 5,
                InfoIcon: "bx bx-badge-check",
                Info: "Design and mockups of products for companies."
            }
        ]
    },
    {
        Id: 2,
        Title: <>UI/UX <br /> Designer</>,
        Icon: <Unicons.UilArrow className="services__icon"/>,
        ServiceButtonTitle: "View More",
        ServiceButtonIcon: <Unicons.UilArrowRight className="services__button-icon"/>,
        ModelTitle: "UI/UX Designer",
        ModelDescription: "Service with more than 1 years of experience.Providing quality work to clients and companies.",
        ModalServicesInfo: [
            {
                InfoId: 1,
                InfoIcon: "bx bx-badge-check",
                Info: "I develop the user interface."
            },
            {
                InfoId: 2,
                InfoIcon: "bx bx-badge-check",
                Info: "Web page development."
            },
            {
                InfoId: 3,
                InfoIcon: "bx bx-badge-check",
                Info: "I create ux element interactions."
            },
            {
                InfoId: 4,
                InfoIcon: "bx bx-badge-check",
                Info: "I position your company brand."
            },
            {
                InfoId: 5,
                InfoIcon: "bx bx-badge-check",
                Info: "Design and mockups of products for companies."
            }
        ]
    },
    {
        Id: 3,
        Title: <>Visual <br /> Designer</>,
        Icon: <Unicons.UilEdit className="services__icon"/>,
        ServiceButtonTitle: "View More",
        ServiceButtonIcon: <Unicons.UilArrowRight className="services__button-icon"/>,
        ModelTitle: "Visual Designer",
        ModelDescription: "Service with more than 1 years of experience.Providing quality work to clients and companies.",
        ModalServicesInfo: [
            {
                InfoId: 1,
                InfoIcon: "bx bx-badge-check",
                Info: "I develop the user interface."
            },
            {
                InfoId: 2,
                InfoIcon: "bx bx-badge-check",
                Info: "Web page development."
            },
            {
                InfoId: 3,
                InfoIcon: "bx bx-badge-check",
                Info: "I create ux element interactions."
            },
            {
                InfoId: 4,
                InfoIcon: "bx bx-badge-check",
                Info: "I position your company brand."
            },
            {
                InfoId: 5,
                InfoIcon: "bx bx-badge-check",
                Info: "Design and mockups of products for companies."
            }
        ]
    }
];

/* ==========================================================================
   STAGE SECTION INFORMATION
========================================================================== */

const Stage = [
    {
        Id: 1,
        Title: "Thinking",
        StageImage: { Img: Thinking, alt: "Thinking Image" },
    },
    {
        Id: 2,
        Title: "Research",
        StageImage: { Img: Research, alt: "Research Image" },
    },
    {
        Id: 3,
        Title: "Design",
        StageImage: { Img: Design, alt: "Design Image" },
    },
    {
        Id: 4,
        Title: "Development",
        StageImage: { Img: Development, alt: "Arrow Image" },
    },
];

/* ==========================================================================
   ACTION SECTION INFORMATION
========================================================================== */

const CallToAction = {
    Title: "Dou you need a new project ?",
    ButtonTitle: "Contact Me",
    Link: "mailto:user141@gmail.com"
};

/* ==========================================================================
   PORTFOLIO SECTION INFORMATION
========================================================================== */

const PortfolioInfo = [
        {
        Id: 1,
        Img: {
            Img: Portfolio_05,
            alt: "Messenger App"
        },
        Title: "Messenger App",
        Date: "01 September 2022",
        Link: "https://www.google.com/",
        Icon: "bx bx-grid-alt",
        Modal: {
            ModalTitle: "Messenger App",
            ModalImg: {
                Img: Portfolio_05,
                alt: "Messenger App"
            },
            ProjectType:"Web Design",
            ViewProject:{
                Link:"https://www.google.com/",
                Name:"View Project",
                Icon:"bx bx-link"
            },
            Like:{
                Name:"1101",
                Icon:"bx bx-heart"
            }
        }
    },
     {
        Id: 2,
        Img: {
            Img: Portfolio_02,
            alt: "Business Agency Landing Page"
        },
        Title: "Business Agency Landing Page",
        Date: "05 September 2022",
        Link: "https://www.google.com/",
        Icon: "bx bx-grid-alt",
        Modal: {
            ModalTitle: "Business Agency Landing Page",
            ModalImg: {
                Img: Portfolio_02,
                alt: "Business Agency Landing Page"
            },
            ProjectType:"Website",
            ViewProject:{
                Link:"https://www.google.com/",
                Name:"View Project",
                Icon:"bx bx-link"
            },
            Like:{
                Name:"521",
                Icon:"bx bx-heart"
            }
        }
    },
    {
        Id: 3,
        Img: {
            Img: Portfolio_03,
            alt: "Booking Service Application - Parking"
        },
        Title: "Booking Service Application - Parking",
        Date: "11 September 2022",
        Link: "https://www.google.com/",
        Icon: "bx bx-grid-alt",
        Modal: {
            ModalTitle: "Booking Service Application - Parking",
            ModalImg: {
                Img: Portfolio_03,
                alt: "Booking Service Application - Parking"
            },
            ProjectType:"Web Design",
            ViewProject:{
                Link:"https://www.google.com/",
                Name:"View Project",
                Icon:"bx bx-link"
            },
            Like:{
                Name:"1101",
                Icon:"bx bx-heart"
            }
        }
    },
    {
        Id: 4,
        Img: {
            Img: Portfolio_04,
            alt: "Personal Website"
        },
        Title: "Personal Website",
        Date: "12 September 2022",
        Link: "https://www.google.com/",
        Icon: "bx bx-grid-alt",
        Modal: {
            ModalTitle: "Personal Website",
            ModalImg: {
                Img: Portfolio_04,
                alt: "Personal Website"
            },
            ProjectType:"Web Design",
            ViewProject:{
                Link:"https://www.google.com/",
                Name:"View Project",
                Icon:"bx bx-link"
            },
            Like:{
                Name:"1101",
                Icon:"bx bx-heart"
            }
        }
    },
    {
        Id: 5,
        Img: {
            Img: Portfolio_01,
            alt: "Innovation and Crafts."
        },
        Title: "Innovation and Crafts.",
        Date: "16 September 2022",
        Link: "https://www.google.com/",
        Icon: "bx bx-grid-alt",
        Modal: {
            ModalTitle: "Innovation and Crafts.",
            ModalImg: {
                Img: Portfolio_01,
                alt: "Innovation and Crafts."
            },
            ProjectType:"Application",
            ViewProject:{
                Link:"https://www.google.com/",
                Name:"View Project",
                Icon:"bx bx-link"
            },
            Like:{
                Name:"5101",
                Icon:"bx bx-heart"
            }
        }
    },
    {
        Id: 6,
        Img: {
            Img: Portfolio_06,
            alt: "Gym Landing Page Design"
        },
        Title: "Gym Landing Page Design",
        Date: "18 September 2022",
        Link: "https://www.google.com/",
        Icon: "bx bx-grid-alt",
        Modal: {
            ModalTitle: "Gym Landing Page Design",
            ModalImg: {
                Img: Portfolio_06,
                alt: "Gym Landing Page Design"
            },
            ProjectType:"Web Design",
            ViewProject:{
                Link:"https://www.google.com/",
                Name:"View Project",
                Icon:"bx bx-link"
            },
            Like:{
                Name:"1101",
                Icon:"bx bx-heart"
            }
        }
    }
];

/* ==========================================================================
   COUNTER SECTION INFORMATION
========================================================================== */

const Counters = [
    {
        Id: 1,
        Title: "Happy Clients",
        Icon: "bx bx-user-circle",
        CounterNumber: 10
    },
    {
        Id: 2,
        Title: "Project Done",
        Icon: "bx bx-code-alt",
        CounterNumber: 20
    },
    {
        Id: 3,
        Title: "Coffee Cups",
        Icon: "bx bx-coffee",
        CounterNumber: 100
    },
    {
        Id: 4,
        Title: "Total Review",
        Icon: "bx bxs-quote-alt-right",
        CounterNumber: 20
    }
];

/* ==========================================================================
   BLOG SECTION INFORMATION
========================================================================== */

const BlogInfo = [
    {
        Id: 1,
        Img: BUI,
        Title: "Learn Prototyping",
        Date: "26 February 2020",
        Link: "https://www.google.com/",
        Icon: "bx bx-link-external"
    },
    {
        Id: 2,
        Img: BDesign,
        Title: "What is Ui/Ux Design",
        Date: "27 February 2020",
        Link: "https://www.google.com/",
        Icon: "bx bx-link-external"
    },
    {
        Id: 3,
        Img: BCoding,
        Title: "Learn Coding",
        Link: "https://www.google.com/",
        Date: "28 February 2020",

        Icon: "bx bx-link-external"
    },
];

/* ==========================================================================
   TESTIMONIAL SECTION INFORMATION
========================================================================== */

const TestimonialData = [
    {
        Id: 1,
        image: Testimonial_01,
        title: "Jhon Galani",
        description:
            "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
        Id: 2,
        image: Testimonial_02,
        title: "Sara Clinton",
        description:
            "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
        Id: 3,
        image: Testimonial_03,
        title: "Harry Doe",
        description:
            "We are extremely satisfied with the results. Web development is the most valuable business resource we have EVER purchased.",
    },
    {
        Id: 4,
        image: Testimonial_04,
        title: "Sendy Lakhani",
        description:
            "It really saves me time and effort. app development is exactly what our business has been lacking. App development is great. Best. Product. Ever!",
    }
];

/* ==========================================================================
   CONTACT SECTION INFORMATION
========================================================================== */

const ContactInfo = {
    ContactTitle: "Talk to me",
    ContactFormTitle: "Write me your message",
    FormName: {
        Id: "name",
        Name: "Name",
        Placeholder: "Your name",
    },
    FormEmail: {
        Id: "email",
        Name: "Email",
        Placeholder: "user141@gmail.com",
    },
    FormMessage: {
        Id: "message",
        Name: "Message",
        Placeholder: "Write your Message",
    },
    ButtonTitle: "Send Message"
}

const ContactCardInfo = [
    {
        Id: 1,
        Title: "Email",
        SubTitle: "user141@gmail.com",
        Link: "mailto:examplemail@gmail.com.com",
        Icon: "bx bx-send",
        ButtonTitle: "Write Me",
        ButtonIcon: "bx bx-right-arrow-alt",

    },
    {
        Id: 2,
        Title: "Whatsapp",
        SubTitle: "(+91) 999-888-777",
        Link: "https://api.whatsapp.com/send?phone={Your Phone Number}&text=Hello, more information!",
        Icon: "bx bxl-whatsapp",
        ButtonTitle: "Write Me",
        ButtonIcon: "bx bx-right-arrow-alt",

    },
    {
        Id: 3,
        Title: "Messenger",
        SubTitle: "user.fb141",
        Link: "https://m.me/crypticalcoder",
        Icon: "bx bxl-messenger",
        ButtonTitle: "Write Me",
        ButtonIcon: "bx bx-right-arrow-alt",

    }
];

const ContactFormSendKey = {
    ServiceId: "YOUR_SERVICE_ID",
    TemplateId: "YOUR_TEMPLATE_ID",
    PublicKey: "YOUR_PUBLIC_KEY"
}

/* ==========================================================================
   FOOTER SECTION INFORMATION
========================================================================== */

const FooterInfo = {
    Title:"Parth Galani",
    Menu:[
        {
            Id:1,
            Name:"Home",
            FLink:"home"
        },
        {
            Id:2,
            Name:"About",
            FLink:"about"
        },
        {
            Id:3,
            Name:"Project",
            FLink:"portfolios"
        },
        {
            Id:4,
            Name:"Testimonial",
            FLink:"testimonial"
        },
    ],
    SocialLink:[
        {
            Id:1,
            Icon:"bx bxl-facebook",
            Link:"https://www.facebook.com/"
        },
        {
            Id:2,
            Icon:"bx bxl-instagram",
            Link:"https://www.instagram.com/"
        },
        {
            Id:3,
            Icon:"bx bxl-twitter",
            Link:"https://twitter.com/"
        }
    ],
    CopyRight:<>Copyright &#169;  <a href="/">Parthify</a>. All rigths reserved</>

}

/* ==========================================================================
   ALL SECTION SVG INFORMATION AND LINKS
========================================================================== */

const allSvg = [
    {
        Id: 1,           
        name:"Mouse Scroll",
        svg: <svg
            className="button__icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="var(--icon-color)"
        >
            <path
                d="M14.2199 21.9352C13.0399 21.9352 11.3699 21.1052 10.0499 17.1352L9.32988 14.9752L7.16988 14.2552C3.20988 12.9352 2.37988 11.2652 2.37988 10.0852C2.37988 8.91525 3.20988 7.23525 7.16988 5.90525L15.6599 3.07525C17.7799 2.36525 19.5499 2.57525 20.6399 3.65525C21.7299 4.73525 21.9399 6.51525 21.2299 8.63525L18.3999 17.1252C17.0699 21.1052 15.3999 21.9352 14.2199 21.9352ZM7.63988 7.33525C4.85988 8.26525 3.86988 9.36525 3.86988 10.0852C3.86988 10.8052 4.85988 11.9052 7.63988 12.8252L10.1599 13.6652C10.3799 13.7352 10.5599 13.9152 10.6299 14.1352L11.4699 16.6552C12.3899 19.4352 13.4999 20.4252 14.2199 20.4252C14.9399 20.4252 16.0399 19.4352 16.9699 16.6552L19.7999 8.16525C20.3099 6.62525 20.2199 5.36525 19.5699 4.71525C18.9199 4.06525 17.6599 3.98525 16.1299 4.49525L7.63988 7.33525Z"
                fill="var(--icon-color)"
            ></path>
            <path
                d="M10.11 14.7052C9.92005 14.7052 9.73005 14.6352 9.58005 14.4852C9.29005 14.1952 9.29005 13.7152 9.58005 13.4252L13.16 9.83518C13.45 9.54518 13.93 9.54518 14.22 9.83518C14.51 10.1252 14.51 10.6052 14.22 10.8952L10.64 14.4852C10.5 14.6352 10.3 14.7052 10.11 14.7052Z"
                fill="var(--icon-color)"
            ></path>
        </svg>
    },
    {
        Id: 2,           
        name:"File",
        svg: <svg
            className="button__icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M15.25 22.7502H9.25C3.82 22.7502 1.5 20.4302 1.5 15.0002V9.00024C1.5 3.57024 3.82 1.25024 9.25 1.25024H14.25C14.66 1.25024 15 1.59024 15 2.00024C15 2.41024 14.66 2.75024 14.25 2.75024H9.25C4.64 2.75024 3 4.39024 3 9.00024V15.0002C3 19.6102 4.64 21.2502 9.25 21.2502H15.25C19.86 21.2502 21.5 19.6102 21.5 15.0002V10.0002C21.5 9.59024 21.84 9.25024 22.25 9.25024C22.66 9.25024 23 9.59024 23 10.0002V15.0002C23 20.4302 20.68 22.7502 15.25 22.7502Z"
                fill="var(--icon-color)"
            ></path>
            <path
                d="M22.25 10.7502H18.25C14.83 10.7502 13.5 9.42023 13.5 6.00023V2.00023C13.5 1.70023 13.68 1.42023 13.96 1.31023C14.24 1.19023 14.56 1.26023 14.78 1.47023L22.78 9.47023C22.99 9.68023 23.06 10.0102 22.94 10.2902C22.82 10.5702 22.55 10.7502 22.25 10.7502ZM15 3.81023V6.00023C15 8.58023 15.67 9.25023 18.25 9.25023H20.44L15 3.81023Z"
                fill="var(--icon-color)"
            ></path>
            <path
                d="M13.25 13.7502H7.25C6.84 13.7502 6.5 13.4102 6.5 13.0002C6.5 12.5902 6.84 12.2502 7.25 12.2502H13.25C13.66 12.2502 14 12.5902 14 13.0002C14 13.4102 13.66 13.7502 13.25 13.7502Z"
                fill="var(--icon-color)"
            ></path>
            <path
                d="M11.25 17.7502H7.25C6.84 17.7502 6.5 17.4102 6.5 17.0002C6.5 16.5902 6.84 16.2502 7.25 16.2502H11.25C11.66 16.2502 12 16.5902 12 17.0002C12 17.4102 11.66 17.7502 11.25 17.7502Z"
                fill="var(--icon-color)"
            ></path>
        </svg>
    },
    {
        Id: 3,           
        name:"Hand",
        svg: <svg
            width="36"
            height="36"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="home__hand"
        >
            <path
                d="M25.4995 32.0305L31.3495 33.1555L36.1495 8.48051C36.4495 6.83051 35.3995 5.18051 33.8245 4.88051C32.1745 4.58051 30.5995 5.70551 30.2995 7.35551L25.4995 32.0305Z"
                fill="#FFDD67"
            ></path>
            <path
                d="M33.8996 4.88018C33.6746 4.80518 33.5246 4.80518 33.2996 4.80518C34.6496 5.33018 35.3996 6.75518 35.0996 8.25518L30.2996 32.9302L31.3496 33.1552L36.1496 8.48018C36.5246 6.75518 35.4746 5.18018 33.8996 4.88018Z"
                fill="#EBA352"
            ></path>
            <path
                d="M19.4995 32.7802H26.5495V5.55518C26.5495 3.53018 24.9745 1.80518 23.0245 1.80518C21.1495 1.80518 19.4995 3.45518 19.4995 5.55518V32.7802Z"
                fill="#FFDD67"
            ></path>
            <path
                d="M23.0995 1.80518C22.9495 1.80518 22.7245 1.80518 22.5745 1.88018C24.2995 2.18018 25.5745 3.68018 25.5745 5.55518V32.8552H26.6245V5.55518C26.6245 3.45518 25.0495 1.80518 23.0995 1.80518Z"
                fill="#EBA352"
            ></path>
            <path
                d="M15.7495 32.7054L21.7495 31.1304L15.2245 6.30541C14.7745 4.58041 13.0495 3.53041 11.3995 3.90541C9.74948 4.35541 8.77448 6.08041 9.22448 7.80541L15.7495 32.7054Z"
                fill="#FFDD67"
            ></path>
            <path
                d="M11.3995 3.90541L10.9495 4.13041C12.4495 4.05541 13.7995 5.03041 14.2495 6.60541L20.7745 31.4304L21.8245 31.1304L15.2245 6.30541C14.7745 4.58041 13.0495 3.53041 11.3995 3.90541Z"
                fill="#EBA352"
            ></path>
            <path
                d="M2.99937 10.355C1.57437 11.03 1.12437 12.83 1.87437 14.33L11.7744 34.055L16.7994 31.505L6.89937 11.78C6.14937 10.28 4.42437 9.68 2.99937 10.355Z"
                fill="#FFDD67"
            ></path>
            <path
                d="M2.99956 10.355C2.84956 10.43 2.69956 10.505 2.54956 10.655C3.82456 10.28 5.24956 10.955 5.92456 12.305L15.8246 32.03L16.7996 31.58L6.89956 11.78C6.14956 10.28 4.42456 9.68 2.99956 10.355Z"
                fill="#EBA352"
            ></path>
            <path
                d="M46.2744 22.2801C45.0744 19.9551 41.3244 20.1051 37.4994 24.3051C34.7994 27.2301 34.2744 28.2051 31.5744 28.1301V25.0551C31.5744 25.0551 25.7994 20.7801 14.3244 22.7301C14.3244 22.7301 7.79945 23.6301 7.79945 27.0801C7.79945 27.0801 6.67445 35.4051 8.99945 40.6551C12.4494 48.4551 30.1494 50.4801 35.6994 37.2051C36.8244 34.5801 39.0744 32.6301 41.0994 30.1551C43.4244 27.1551 47.5494 24.7551 46.2744 22.2801Z"
                fill="#FFDD67"
            ></path>
            <path
                d="M46.2745 22.28C46.0495 21.83 45.7495 21.53 45.3745 21.23C45.4495 21.305 45.5245 21.38 45.5245 21.53C46.7995 24.08 42.6745 26.405 40.1995 29.405C38.1745 31.88 35.9245 33.83 34.7995 36.455C29.9995 47.93 16.0495 47.93 10.1995 42.68C15.5245 48.68 30.5245 49.28 35.5495 37.205C36.6745 34.58 38.9245 32.63 40.9495 30.155C43.4245 27.155 47.5495 24.755 46.2745 22.28ZM32.3245 28.13C27.4495 26.33 18.7495 29.63 19.9495 38.405C19.9495 30.23 27.3745 28.205 31.4245 28.205C32.0245 28.13 32.3245 28.13 32.3245 28.13Z"
                fill="#EBA352"
            ></path>
        </svg>
    }, {
        id: 4,           
        name:"Send Message",
        svg: <svg
            width="32px"
            height="32px"
            className="home__scroll-mouse"
            viewBox="0 0 247 390"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: '1.5',
            }}
        >
            <path
                className="wheel"
                d="M123.359,79.775l0,72.843"
                style={{
                    fill: 'none',
                    stroke: 'var(--title-color)',
                    strokeWidth: '20px'
                }}
            ></path>
            <path
                id="mouse"
                d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
                style={{
                    fill: 'none',
                    stroke: 'var(--title-color)',
                    strokeWidth: '20px'
                }}
            ></path>
        </svg>
    },
    {
        id: 5,        
        name:"Zigzag",
        svg: <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="5rem" height="5rem" viewBox="0 0 500.000000 240.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
       fill="var(--title-color-dark)" stroke="none">
       <path d="M987 1802 l-87 -87 263 -263 c144 -144 267 -262 272 -262 5 0 90 80
       187 177 l178 178 180 -180 180 -180 180 180 180 180 178 -178 c97 -97 182
       -177 187 -177 5 0 89 79 186 176 l176 176 176 -176 c97 -97 180 -176 184 -176
       5 0 126 118 271 263 l262 262 -88 88 -87 87 -180 -180 -180 -180 -180 180
       -180 180 -180 -180 -180 -180 -182 182 -183 183 -183 -183 -182 -182 -180 180
       -180 180 -180 -180 -180 -180 -180 180 -180 180 -88 -88z"/>
       <path d="M987 1112 l-87 -87 263 -263 c144 -144 265 -262 269 -262 4 0 84 79
       179 175 95 96 178 175 183 175 6 0 91 -80 188 -177 l178 -178 180 180 180 180
       180 -180 180 -180 178 178 c97 97 182 177 187 177 5 0 90 -80 187 -177 l178
       -178 265 265 265 265 -87 87 -88 88 -180 -180 -180 -180 -180 180 -180 180
       -180 -180 -180 -180 -183 182 -182 183 -183 -183 -182 -182 -180 180 -180 180
       -180 -180 -180 -180 -180 180 -180 180 -88 -88z"/>
       </g>
       </svg>
    },
    {
        id: 6,
        name:"Arrow",
        svg: <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="565.000000pt" height="442.000000pt" viewBox="0 0 565.000000 442.000000"
        preserveAspectRatio="xMidYMid meet">
           <g transform="translate(0.000000,442.000000) scale(0.100000,-0.100000)"
           fill="var(--title-color)" stroke="none">
           <path d="M2150 2191 c0 -10 3 -39 6 -65 l7 -46 133 0 132 0 4 65 3 65 -142 0
           c-133 0 -143 -1 -143 -19z"/>
           <path d="M1715 2154 c-65 -14 -121 -27 -124 -30 -5 -5 15 -113 22 -121 2 -2
           46 5 98 16 52 10 111 22 132 25 41 8 40 2 21 109 -6 32 3 32 -149 1z"/>
           <path d="M2726 2163 c-3 -10 -8 -39 -11 -64 l-7 -45 132 -28 c72 -15 133 -26
           134 -24 6 8 29 119 25 122 -4 4 -244 56 -258 56 -5 0 -12 -8 -15 -17z"/>
           <path d="M1170 2002 c-69 -25 -127 -46 -129 -48 -2 -2 7 -30 20 -64 18 -45 28
           -59 39 -54 8 4 64 24 125 46 60 22 111 41 113 43 5 4 -28 107 -37 117 -3 3
           -62 -14 -131 -40z"/>
           <path d="M3262 1973 c-12 -31 -19 -60 -17 -64 3 -3 31 -17 62 -29 32 -13 84
           -36 117 -52 32 -16 59 -28 61 -26 9 11 56 111 53 114 -7 6 -212 101 -234 108
           -18 6 -24 -1 -42 -51z"/>
           <path d="M702 1805 c-30 -14 -52 -28 -50 -32 2 -5 16 -32 31 -60 l27 -53 55
           27 c30 15 55 31 55 35 0 15 -50 108 -58 108 -4 0 -31 -11 -60 -25z"/>
           <path d="M3759 1708 l-33 -51 24 -16 c13 -9 61 -44 107 -78 46 -35 85 -63 88
           -63 6 0 75 91 75 99 0 12 -205 161 -221 161 -4 0 -22 -23 -40 -52z"/>
           <path d="M4483 1530 c-40 -16 -38 -50 17 -301 29 -132 50 -242 48 -244 -2 -2
           -93 19 -202 46 -185 46 -200 49 -222 34 -31 -20 -40 -59 -21 -89 14 -20 48
           -31 232 -77 201 -50 218 -56 231 -81 l14 -27 53 24 c88 41 87 22 12 372 -58
           266 -71 311 -92 331 -26 24 -36 26 -70 12z"/>
           <path d="M4200 1355 l-43 -44 89 -93 c48 -51 94 -92 101 -92 13 2 90 64 92 74
           1 8 -179 200 -188 200 -4 0 -26 -20 -51 -45z"/>
           </g>
       </svg>
       
    }
]

/* ==========================================================================
   THEME MODE COLOR INFO
========================================================================== */

const colorThemeMode = [
    {
        Id:1,
        Color:"#FFF",
        Title:"Lite Theme",
        ClassName: "light-theme",
        Img: Light_Theme,
    }, 
    {
        Id:2,
        Color:"#000",
        Title:"Dark Theme",
        ClassName: "dark-theme",
        Img: Dark_Theme
    }, 
    {
        Id:3,
        Color:"#ecfe21",
        Title:"Dark Yellow Theme",
        ClassName: "dark_yellow-theme",
        Img: Dark_Yellow_Theme
    },
    {
        Id:4,
        Color:"#ecfe21",
        Title:"Light Yellow Theme",
        ClassName: "light_yellow-theme",
        Img: Light_Yellow_Theme
    },
    {
        Id:5,
        Color:"#fb5b0f",
        Title:"Light Orange Theme",
        ClassName: "light_orange-theme",
        Img: Light_Orange_Theme
    },
    {
        Id:6,
        Color:"#fb5b0f",
        Title:"Dark Orange Theme",
        ClassName: "dark_orange-theme",
        Img: Dark_Orange_Theme
    },
    {
        Id:7,
        Color:"#e21152",
        Title:"Dark Pink Theme",
        ClassName: "dark_pink-theme",
        Img: Dark_Pink_Theme
    },
    {
        Id:8,
        Color:"#e21152",
        Title:"Light Pink Theme",
        ClassName: "light_pink-theme",
        Img: Light_Pink_Theme
    },    
    {
        Id:9,
        Color:"#ecfe21",
        Title:"Light Blue Theme",
        ClassName: "light_blue-theme",
        Img: Light_Blue_Theme
    },
];

export { 
    Title,     
    HeaderMenu, 
    Home, 
    SocialLink, 
    About, 
    aboutInfo, 
    Skills, 
    FrontendSkills, 
    BackendSkills, 
    Qualifications, 
    QulEducation, 
    QulExperience, 
    Service, 
    Stage, 
    CallToAction,     
    PortfolioInfo,
    Counters, 
    BlogInfo, 
    TestimonialData, 
    ContactCardInfo, 
    ContactInfo, 
    ContactFormSendKey, 
    FooterInfo,
    allSvg,    
    colorThemeMode,
};